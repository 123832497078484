import React, { Component } from "react";

class Projects extends Component {
  state = {};
  render() {
    return <h1>WIP</h1>;
  }
}

export default Projects;
